.player-epg
{
	width: 100%;
	height: 322px;
	overflow: hidden;
}
.player-epg-header
{
	height: 50px;
	width: 100%;
	text-align: center;
	border-bottom: 1px solid #252525;
}
.epg-header-content
{
	position: relative;
	font-size: 0;
}
.epg-header-content .close
{
	position: absolute;
	right: 0;
	top: 0;
	height: 50px;
	width: 50px;
	background-image: url("./../images/epg_close.svg");
	background-position: center;
	background-size: 14px;
	background-repeat: no-repeat;
	cursor: pointer;
}
.epg-schedule,
.epg-live-now
{
	display: inline-block;
	vertical-align: middle;
	color: #a0aab3;
	font-size: 16px;
	cursor: pointer;
	padding: 0 14px;
	font-weight: 400;
	line-height: 50px;
	transition: all .3s;
}
.epg-schedule:hover,
.epg-live-now:hover
{
	color: #00c473;
	font-weight: 600;
}
.epg-schedule.active,
.epg-live-now.active
{
	font-weight: 600;
	color: #00c473;
	box-shadow: inset 0px -2px 0px #00c47c;
}
.epg-list
{
	display: none;
}
.epg-list.active,
.epg-current-now.active.owl-carousel.owl-loaded
{
	display: block;
}
.player-epg .channel-type-2 .program-wrap
{
	width: 262px;
}
.player-container
{
	position: relative;
	overflow: hidden;
}
/*Free Sample Start*/
.freesample-container {
	position: absolute;
	right: 20px;
	top: 20px;
	height: 60px;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: 400;
}
.freesample-signin {
	padding-left: 20px;
	padding-right: 20px;
	background: rgba(212, 212, 212, 0.89);
	height: 100%;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	line-height: 60px;
	color: #636363;
	font-weight: 600;
	border: 2px solid rgba(255, 255, 255, 0);
}
.freesample-container:hover .freesample-signin {
	background: rgba(212, 212, 212, 1);
}
.freesample-container:hover .freesample-countdown {
	background: rgba(0, 0, 0, 1);
}
.freesample-countdown {
	color: #fff;
	padding-left: 20px;
	padding-right: 20px;
	background: rgba(0, 0, 0, 0.3);
	border: 2px solid #4d4d4d;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	height: 100%;
	line-height: 56px;
}
.freesample-countdown-timer {
	font-size: 22px;
	font-weight: 600;
	width: 56px;
	display: inline-block;
}
@media (max-width: 767px) {
	.freesample-container {
		height: 48px;
		font-size: 14px;
		right: 10px;
	}

	.freesample-signin {
		line-height: 48px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.freesample-countdown {
		line-height: 44px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.freesample-countdown-timer {
		font-size: 18px;
		width: 44px;
	}
}
@media (max-width: 483px) {
	.freesample-container {
		height: 36px;
		font-size: 12px;
		right: 2px;
	}

	.freesample-signin {
		line-height: 36px;
		padding-left: 8px;
		padding-right: 8px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		min-width: 60px;
		text-align: center;
	}

	.freesample-countdown {
		line-height: 32px;
		padding-left: 2px;
		padding-right: 2px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.freesample-countdown-timer {
		font-size: 16px;
	}
}

@media (max-width: 375px) {
	.freesample-signin {
		padding-left: 5px;
		padding-right: 5px;
		font-size: 10px;
	}

	.freesample-countdown {
		font-size: 10px;
	}
}
/*Free Sample End*/
#epg
{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 10;
}
@media (max-width: 479px) {
	#epg
	{
		display: none !important;
	}
}
#epg.show
{
	animation: fadeInUp .3s;
}
@keyframes fadeInUp
{
	from
	{
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to
	{
		opacity: 1;
		transform: none;
	}
}
.player-container .back
{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 21;
	width: 50px;
	height: 50px;
	background-image: url('./../images/player_back.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px;
	cursor: pointer;
	opacity: .6;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-ms-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}
.player-container .back:hover
{
	opacity: 1;
}
/* tv rating logo start*/
.player-container .player-tvRating
{
	position: absolute;
	left: 25px;
	top: 50px;
}
.player-tvRating__logo
{
	width: 15vmin;
	max-width: 90px;
}
/* tv rating logo end*/
.player-epg .program
{
	width: 262px;
}
.player-epg .program .program-wrap
{
	width: 100%;
}
.player-epg .epg-list .program
{
	margin: 0 0 15px 0;
}
.player-epg .live,
.player-epg .dot,
.player-epg .upcoming
{
	display: none;
}
.player-epg .live
{
	color: #01a35f;
	font-weight: 600;
}
.player-epg .upcoming
{
	color: #b70090;
	font-weight: 600;
}
.player-epg .live-program .live,
.player-epg .live-program .dot,
.player-epg .next-program .dot,
.player-epg .next-program .upcoming
{
	display: inline-block;
}
.program__img-content
{
	overflow: hidden;
	width: 100%;
}
.player-epg .upcoming-program,
.player-epg .upcoming-program .program__img-content,
.player-epg .upcoming-program .program__title,
.player-epg .is-not-available .program__img-content,
.player-epg .is-not-available .program__title
{
	cursor: not-allowed;
}
.player-epg .upcoming-program .program__img-wrap:hover .program__img,
.player-epg .is-not-available .program__img-wrap:hover .program__img
{
	transform: none;
}
.player-epg .upcoming-program .program__title:hover,
.player-epg .is-not-available .program__title:hover
{
	text-decoration: none;
}
.player-epg .upcoming-program .program__mask,
.player-epg .is-not-available .program__mask
{
	display: none;
}
.player-epg .is-playing .program__mask
{
	opacity: 1;
}
.player-epg .program__icon
{
	text-indent: -9999px;
}
.player-epg .is-playing .program__icon
{
	width: auto;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #a0aab3;
	font-weight: 800;
	background: none;
	text-indent: 0;
}
.epgComponent .owl-theme .owl-dots
{
	display: none;
}
.player-epg-wrap .owl-carousel .owl-nav .owl-prev,
.player-epg-wrap .owl-carousel .owl-nav .owl-next
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	background-color: rgba(0, 0, 0, 0);
	background-position: center center;
	background-size: auto 50px;
	background-repeat: no-repeat;
	opacity: .3;
	-webkit-transition: all 0.567s;
	-moz-transition: all 0.567s;
	-ms-transition: all 0.567s;
	-o-transition: all 0.567s;
	transition: all 0.567s;
}
.player-epg-wrap .owl-carousel .owl-nav .owl-prev,
.player-epg-wrap .owl-carousel .owl-nav .owl-next
{
	top: 74px;
}
#epg-current-now .owl-carousel .owl-nav .owl-prev,
#epg-current-now .owl-carousel .owl-nav .owl-next
{
	top: 119px;
}
.player-epg-wrap .owl-carousel:hover .owl-prev,
.player-epg-wrap .owl-carousel:hover .owl-next
{
	opacity: .5;
}
.player-epg-wrap .owl-carousel .owl-nav .owl-prev:hover,
.player-epg-wrap .owl-carousel .owl-nav .owl-next:hover
{
	opacity: .8;
}
.player-epg-wrap .owl-carousel .owl-nav .owl-prev
{
	left: 5px;
	background-image: url("./../images/arrow_left.svg");
}
.player-epg-wrap .owl-carousel .owl-nav .owl-next
{
	right: 5px;
	background-image: url("./../images/arrow_right.svg");
}
.player-epg-wrap .owl-carousel .owl-item {
	-webkit-transform: none;
}
.player-epg-wrap
{
	padding: 0 15px;
}
#nlPlayerContainer
{
	/*width: 100vw;*/
	height: 100%;
}
.neu-overlay.is-neu-overlay-open
{
	z-index: 11;
}
.player-epg-wrap .owl-carousel .owl-nav [class*=owl-]
{
	transition: visibility 2s linear;
}
.player-epg-wrap .owl-carousel .owl-nav [class*=owl-].disabled
{
	opacity: 0;
	visibility: hidden;
}
.slide-container
{
	position: relative;
	overflow: hidden;
	height: 30px;
}
.slide-list
{
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
}
.slide-list li
{
	height: 36px;
	line-height: 36px;
}
.schedule-time
{
	width: 100%;
	height: 36px;
	font-size: 18px;
	font-weight: 700;
}
.nl-thumbnail-container
{
	display: none;
}


#common-player, #player-popup, .player-container
{
	height: 100%;
	width: 100vw;
}

/* Fit Chromecast Persistent Control */
body.chromecastEnabled .player-container
{
	width: 100%;
}
body.chromecastEnabled #nlPlayerContainer
{
	height: 100% !important;
}
div:-webkit-full-screen
{
	display: flex;
}
